<template>
<section class="loader">
    <div class="page-header">
      <h3 class="page-title" v-if="site"> {{site.name}} <span class="text-muted"> {{site.canonicalurl}} </span> </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Nom de la page</a></li>
          <li class="breadcrumb-item active" aria-current="page">Information de la page</li>
        </ol>
      </nav>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-end">
          
          
        </div>
        
         <!-- Liste des pages -->
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex justify-content-between card-title">
                    <h4>Liste des pages</h4>
                    <!-- Ajouter une nouvelle Page -->
                    <button class="btn btn-success" v-b-modal.addPage >Ajouter</button>
                  </div>
                  <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>Nom de la page</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="page in pages" :key="page.id">
                          <td class="pointer" @click="showSection(page)">{{page.title}}</td>
                          <td>
                            <i class="fa fa-pencil"></i>
                            <i class="fa fa-trash mx-4 pointer" @click="alertDelete(page)"></i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>

      <!-- modal ajouter une nouvelle page -->
      <b-modal id="addPage" title="Ajouter une page" hide-footer>
        <!-- titre de la page -->
        <b-form-group label="Titre de la page"  label-for="title">
          <b-form-input type="text" id="title" placeholder="Titre de la page" v-model="addPageData.title"></b-form-input>
        </b-form-group>
        <!-- description de la page -->
        <b-form-group label="Description de la page"  label-for="description">
          <b-form-textarea type="text" id="description" v-model="addPageData.description" placeholder="Description de la page"></b-form-textarea>
        </b-form-group>
        <!-- Mot clés de la page -->
        <b-form-group label="Mots clés"  label-for="keywords">
          <b-form-tags input-id="tags-basic" v-model="addPageData.keywords"  placeholder="Ajouter des mots clés"></b-form-tags>
        </b-form-group>
        <hr>
        <div class="d-flex justify-content-end">
          <button class="btn btn-link mx-4" @click="$bvModal.hide('addPage')">Annuler</button>
          <button class="btn btn-success" @click="addPage()">Ajouter</button>
        </div>
      </b-modal>
    </div>
</section>
</template>

<script>
const axios = require ('axios').default;
export default {
    name: 'pages',
    components: {
    },
    data () {
      return {
        subMenu:'sitePage',
        pages: [],
        site: null,
        ulidsite:'',
        addPageData: {
          title: '',
          description: '',
          keywords: []
        },
        modeEdit: false
      }
    },
    methods: {
      getPages (ulidsite) {
        axios.get(`sites/${ulidsite}`)
        .then (resGetPages =>{
          console.log({resGetPages})
          this.pages = resGetPages.data.pages
          this.site= resGetPages.data
          }
        )
        .catch (errGetPages => {
          console.log({errGetPages})
        })
      },
      showSection(page) {
        console.log("page_slugname",page.slugname);
        this.$router.push({
          name: 'page-preview',
              params: {
                ulid: page.ulid,
                page: page.slugname
              }
          })
        },
      // Ajout d'une nouvelle page
      addPage () {
        axios.post(`pages`, {
          ulidsite:this.ulidsite,
          title: this.addPageData.title,
          description: this.addPageData.description,
          keywords: this.addPageData.keywords
        },
        {
          headers: {
            'X-AUTH-TOKEN': localStorage.getItem('token')
          }
        }
        )
        .then (resAddPage => {
          console.log({resAddPage})
          this.getPages(this.ulidsite)
        })
        .catch (errAddPage => console.log({errAddPage}))
      },
      deletePage(item) {
        axios.delete(
          `pages/${item.ulid}`,
          {
            headers: {
              'X-AUTH-TOKEN': localStorage.getItem('token')
            }
          }
        )
        .then(resDeletePage => {
          this.$swal(
            "Supprimé!",
            "La page a bien été supprimée",
            "success"
          );
          this.getPages(this.ulidsite)
          console.log(resDeletePage) })
        .catch(errDeletePage => { console.log(errDeletePage) })
      },
      alertDelete(page) {
       this.$swal({
        title: "Êtes-vous sûr?",
        text: "Voulez-vous supprimer cette page?" + page.title,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, Supprimer",
        cancelButtonText: "Annuler",
      }).then((result) => {
        if (result.value) {
          this.deletePage(page)
        }
      })
      },
    },
    created () {
      //récupération de l'ulid pour faire le get des informations du site
      this.ulidsite= this.$route.params.ulidsite
      this.getPages(this.ulidsite)
    },
  }
</script>
<style scoped>
.date {
    margin-bottom: 5px;
    color: #9c9fa6;
    font-size: .9375rem;
    line-height: 1.5;
}

.portfolio-grid figure {
  color: black;
  background: none !important;
}
.portfolio-grid figure figcaption {
  color: rgb(129, 129, 129);
}
figure img {
  filter: blur(2px)
}
figure img :hover {
  filter: blur(0px);
}
/* delete */
.construct{
height: 2rem;
width: 2rem;
border-radius: 50%;
position: absolute;
right: 1rem;
top: 1rem;
font-size: 1rem;
}
</style>